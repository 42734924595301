<template>
    <div class="my-10">
        <div class="text-center"><img src="https://assets.cdn.filesafe.space/kwfN8FUUQKkPqHdkO3mV/media/61c23255aa3de485b16ddf5f.png" 
            alt="Dev Amplifier Logo"
            class="inline"
            width="250"
            ></div>
        
        <p class="text-center mt-4">© Copyright 2024, Sommardahl Ventures.</p>
    </div>
</template>

<script setup>
</script>

<style scoped>
</style>
