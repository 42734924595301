<template>
    <UContainer class="snap-x">
        <div class="mt-28">
            <slot></slot>        
        </div>
        <Footer></Footer>  
    </UContainer>   
</template>

<style>
html {
    scroll-behavior: smooth;
}
</style>